const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const RES = {
    images: {
        logo: 'https://facings.fra1.digitaloceanspaces.com/skullz/logo.png',
        campaignImage: 'https://facings.fra1.digitaloceanspaces.com/skullz/campaignImage.png',
        icon: 'https://facings.fra1.digitaloceanspaces.com/skullz/icon.png',

        pack: 'https://facings.fra1.digitaloceanspaces.com/skullz/single%20pass.png',
        box: 'https://facings.fra1.digitaloceanspaces.com/skullz/vip%20pass.png',
        packAndBox: '',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        soundrLogo: 'https://facings.fra1.digitaloceanspaces.com/skullz/soundr.png'
    },

    videos: {
        packOpenAnimation: 'https://facings.fra1.cdn.digitaloceanspaces.com/skullz/skullz_single_pack_opener.webm',
        boxOpenAnimation: 'https://facings.fra1.cdn.digitaloceanspaces.com/skullz/skullz_vip_pack_opener.webm'
    },

    openingAnimations: [
        {
            template_id: IS_PRODUCTION ? '208770' :'134594',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/skullz/Skullz%20Pack%20WHITE_SFX_Guitar%20Riff.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '208771' : '134595',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/skullz/Skullz%20Pack%20PINK_SFX_Guitar%20Riff.mp4'
        },
        {
            template_id: 'craft',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/skullz/_Skullz_Intro_Music_On.mp4'
        }
    ],

    samples: [
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/skullz/samples/raw%20talent_orkid_mugshot.JPG',
            caption: 'Raw Talent'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/skullz/samples/sketched%20out%20A_orkid_mugshot.JPG',
            caption: 'Sketched Out A'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/skullz/samples/sketched%20out%20B_orkid_mugshot.JPG',
            caption: 'Sketched Out B'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/skullz/samples/skullz_orkid_mugshot.JPG',
            caption: 'Skullz'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/skullz/samples/patch_orkid_mugshot_colour.png',
            caption: 'Patch'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: "https://wax.atomichub.io/market?collection_name=skullz"
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: "https://wax.atomichub.io/profile/{0}?collection_name=skullz"
        },
        atomicHubEntireCollection: {
            label: "AtomicHub Dark Pinup entire Collection",
            link: "https://wax.atomichub.io/tools/book/skullz/{0}"
        },
        waxStash: {
            label: "WAXStash",
            link: "https://www.waxstash.com/markets?collection_name=skullz"
        },
        nftHive: {
            label: "NFTHive",
            link: "https://nfthive.io/collection/skullz"
        }
    },

    externalLinks: {
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        skullzTwitter: {
            label: "Soundr Twitter Account",
            link: "https://twitter.com/soundrmusic"
        },
        soundr: {
            label: "Soundr",
            link: "http://soundr.space/"
        }
    },

    packs: [
        {
            srcImg: 'QmfDKFhPgrNFkySJqUdFw93JCm97f8oUjRK2eLi2A2HAzS',
            caption: 'Single Pack',
            quantity: '1652 Sold',
            description: 'Single Pack of 3 NFTs',
            probabilities:
            [
                { rarity: 'Raw Talent', probability: '29.88%' },
                { rarity: 'Sketched Out A Side', probability: '20.88%' },
                { rarity: 'Sketched Out B Side', probability: '20.88%' },
                { rarity: 'Skullz', probability: '15.84%' },
                { rarity: 'Punk Patches', probability: '5.92%' },
                { rarity: 'Amped Up', probability: '5.4%' },
                { rarity: 'Get Loud', probability: '1.2%' },
                { rarity: 'Ticket Stub', note: 'additional chance', probability: '0.98%' },
                { rarity: "Pencil'd In", note: 'additional chance', probability: '0.0054%' },
                { rarity: 'Timelapse', note: 'Unique for Single Pass', probability: '1 per pack' }
            ]
        },
        {
            srcImg: 'QmTFkaPMmrz4i7t2XDtBDt1QWfPQN6dfWMrPSz79xNtGgg',
            caption: "VIP Pass Pack",
            quantity: '916 Sold',
            description: 'VIP Pass Pack of 15 NFTs',
            probabilities:
            [
                { rarity: 'Raw Talent', probability: '28.8%' },
                { rarity: 'Sketched Out A Side', probability: '20.4%' },
                { rarity: 'Sketched Out B Side', probability: '20.4%' },
                { rarity: 'Skullz', probability: '15.6%' },
                { rarity: 'Punk Patches', probability: '6.4%' },
                { rarity: 'Amped Up', probability: '6.6%' },
                { rarity: 'Get Loud', probability: '1.8%' },
                { rarity: 'Ticket Stub', note: 'additional chance', probability: '1.7%' },
                { rarity: "Pencil'd In", note: 'additional chance', probability: '0.016%' },
                { rarity: 'Timelapse', note: 'Unique for VIP Pass', probability: '1 per pack' }
            ]
        }
    ]
};

export default RES;
