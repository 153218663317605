import { SHOW_FAQ, SHOW_BUY_PAGE, WAX_SIGN_IN, SHOW_CRAFT, SHOW_PACK_HISTORY } from '@utils/globals';

const ROUTES = {
    home: '/home',
    buypacks: SHOW_BUY_PAGE ? '/shop' : '/not_found',
    faq: SHOW_FAQ ? '/faq' : '/not_found',
    inventory: WAX_SIGN_IN ? '/inventory' : '/not_found',
    packrips: WAX_SIGN_IN && SHOW_PACK_HISTORY ? '/pack_rips': '/not_found',
    craft: WAX_SIGN_IN && SHOW_CRAFT? '/craft': '/not_found',
    view: WAX_SIGN_IN ? '/view' : '/not_found',
    resources: WAX_SIGN_IN ? '/resources' : '/not_found',
    terms: '/terms',
    notfound: '/not_found',
    paymenthistory: '/payment_history'
};

export default ROUTES;
