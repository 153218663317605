import React, { useEffect, useState } from 'react';
import { getTransactionsStatus } from './api';
import { getBrowserContext } from './globals';
import { getDropInfo, getInfoFromTemplateId } from '@api/api';
import FiatTransactionCard from './FiatTransactionCard';
import { PACK_DROPS_LIST, WAX_SIGN_IN } from '@utils/globals';
import Container from '@components/Container';
import Modal from '@components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FIAT_TRANSACTION_STATES } from './globals';

const NotFound = styled.h5(({ theme }) => ({
    ...theme.typography.h5
}));

let timeout = null;

function FiatHistory () {
    const theme = useTheme();

    const [ data, setData ] = useState([]);
    const [ packsInfoList, setPacksInfoList ] = useState([]);
    const [ packsImages, setPacksImages ] = useState({});
    const [ transactionToApprove, setTransactionToApprove ] = useState(null);

    useEffect(() =>  {
        let aux = [];
        let count = 0;
        let isMounted = true;
        PACK_DROPS_LIST.forEach((drop_id, index) => {
            getDropInfo(
                parseInt(drop_id),
                (info) => { aux[index] = info; }
            ).then(() => {
                if (!isMounted) {return;}
                count === PACK_DROPS_LIST.length - 1 ? setPacksInfoList(aux) : count += 1;
            });
        });

        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);

        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        // TODO: review this
        for (let packInfo of packsInfoList) {
            getInfoFromTemplateId(packInfo.template_id, (value) => {

                setPacksImages(currentValue => {
                    let newPacksImages = { ...currentValue };
                    newPacksImages[packInfo.template_id] = value;
                    return newPacksImages;
                });
            });
        }
    }, [packsInfoList]);

    useEffect(() => {
        autoRefresh();

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        let payPendingTransactions = data.filter(d => d.status === FIAT_TRANSACTION_STATES.pay_pending.key && d.redirect);
        if (payPendingTransactions.length === 1) {
            setTransactionToApprove(payPendingTransactions[0]);
            setTimeout(() => {
                window.location.href = payPendingTransactions[0].redirect;
            }, 2000);
        }
    }, [data]);

    const loadTransactions = () => {
        getTransactionsStatus(getBrowserContext(), setData);
    };

    const autoRefresh = () => {
        loadTransactions();
        timeout = setTimeout(autoRefresh, 15000);
    };

    const renderTableData = () => {
        return data.map((d) => {
            return <FiatTransactionCard key={d.transaction_id} transaction={d} packInfos={packsInfoList} packsImages={packsImages} />;
        });
    };

    return (
        <div>
            { data.length > 0 ?
                <>
                    {renderTableData()}
                    <Modal show={transactionToApprove != null}>
                        Authorizing your card. Please wait and do not close the page or go back.
                    </Modal>
                </>
                :
                <Container direction="column" padding={theme.spacing.xl}>
                    <NotFound>No transactions found!</NotFound>
                </Container>
            }
        </div>
    );
}

export default FiatHistory;
