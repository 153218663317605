import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Container from '@components/Container';
import STRINGS from '@utils/strings';
import Tabs from '@components/Tabs';

const tabs = [
    STRINGS.termsPage.terms,
    STRINGS.termsPage.privacy,
    STRINGS.termsPage.license
];

function Terms() {
    const theme = useTheme();

    const [ termsToDisplay, setTermsToDisplay ] = useState(tabs[0]);

    const renderTerms = () => {
        if (termsToDisplay === tabs[0]) {
            return STRINGS.termsPage.termsOfService;
        } else if (termsToDisplay === tabs[1]) {
            return STRINGS.termsPage.privacyPolicy;
        } else {
            return STRINGS.termsPage.LicenseAgreement;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Container
                direction="column"
                margin={`${theme.spacing.xl} ${theme.spacing.xs}`}
                gap={theme.spacing.xl}
                isFullWidth={false}
                justifyContent="center"
                alignItems="center"
            >
                <Tabs tabs={tabs} onChange={setTermsToDisplay} selectedTab={termsToDisplay} />
                <Container
                    direction="column"
                    isFullWidth={false}
                    gap={theme.spacing.xs}
                    alignItems="flex-start"
                    margin={`0 ${theme.spacing.xs}`}
                    style={{ lineHeight: '160%' }}
                >
                    { renderTerms() }
                </Container>
            </Container>
        </Container>
    );
}

export default Terms;