import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { withUAL } from 'ual-reactjs-renderer';
import Container from '@components/Container';
import Input from '@components/Input';
import Button from '@components/Button';
import Loading from '@components/Loading';
import IsReleased from '@components/IsReleased';
import ErrorModal from '@components/ErrorModal';
import ConfirmationModal from '@components/ConfirmationModal';
import SuccessModal from '@components/SuccessModal';
import { getInfoFromTemplateId, buyPacks, getAccountInfo } from '@api/api';
import { IPFS_ENDPOINT, WAX_SIGN_IN, FIAT_PAYMENT_ENABLED, WAX_PAYMENT, WAX, FIAT, SINGLE_PROBABILITY } from '@utils/globals';
import { STRINGS } from '@utils/strings';
import { isCPUErrorMessage, isNETErrorMessage } from '@utils/utils';
import { useAccount } from '@hooks/AccountContext';
import FiatIframe from '@components/Fiat/FiatIframe';

const Image = styled.img(({ theme, IsReleased }) => {
    let releasedStyle = IsReleased ? {} : { opacity: 'grayscale(0)' };

    return {
        height: '30vh',
        maxHeight: '400px',
        margin: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xxs} ${theme.spacing.m}`,
        objectFit: 'contain',
        userSelect: 'none',
        ...releasedStyle
    };
});

const Video = styled.video(({ theme, IsReleased }) => {
    let releasedStyle = IsReleased ? {} : { opacity: 'grayscale(0)' };

    return {
        height: '30vh',
        maxHeight: '400px',
        margin: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xxs} ${theme.spacing.m}`,
        objectFit: 'contain',
        userSelect: 'none',
        ...releasedStyle
    };
});

const TicketTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.common.black,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const TicketDescription = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.dark
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.common.black,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.dark,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        width: 395
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary.main}69`
}));

const ButtonCaption = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.dark,
    marginTop: theme.spacing.xxs
}));

const Prices = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    marginTop: theme.spacing.s,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.colors.secondary.contrastText
}));

const CPUErrorMessage = styled.p(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.s,
    textAlign: 'initial',
    a: {
        color: theme.colors.secondary.main,
        '&:hover': {
            color: theme.colors.secondary.dark
        }
    }
}));

function Pack({ packInfo, templateId, ual }) {
    const isReleased = IsReleased();
    const theme = useTheme();

    const [ packImg, setPackImg ] = useState({});
    const [ packImageUrl, setPackImageUrl ] = useState(null);
    const [ purchaseAmount, setPurchaseAmount ] = useState(1);
    const [ showBuyConfirmationModal, setShowBuyConfirmationModal ] = useState(false);
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ usdPrice, setUsdPrice ] = useState(packInfo.get(WAX).formattedUSDPrice ? packInfo.get(WAX).formattedUSDPrice : packInfo.get(FIAT).formattedUSDPrice);
    const [ waxPrice, setWaxPrice ] = useState(packInfo.get(WAX).formattedWAXPrice);
    const [ price, setPrice ] = useState("");
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ fiatInformation, setFiatInformation ] = useState(null);
    const [ showIframeModal, setShowIframeModal ] = useState(false);
    const { accountInformation, setAccountInformation } = useAccount();

    const customInputStyle = {
        alignItems: 'center',
        marginBottom: theme.spacing.s,
        input: {
            maxWidth: 70
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (!isMounted) {return;}
        getInfoFromTemplateId(templateId, setPackImg);
        generatePriceString();
        return () => isMounted = false;
    }, []);

    useEffect(() => {
        if (ual.activeUser) {
            updateAccountInfo();
        }
    }, []);

    useEffect(() => {
        if (packImg.imgHash) {
            setPackImageUrl(IPFS_ENDPOINT + packImg.imgHash);
        }
    }, [packImg]);

    useEffect(() => {
        generatePriceString();
    }, [purchaseAmount]);

    const updateResourceInformation = () => {
        getAccountInfo(ual.activeUser, (info) => {
            if (JSON.stringify(accountInformation) !== JSON.stringify(info)) {
                setAccountInformation(info);
            } else {
                updateResourceInformation();
            }
        });
    };

    const updateAccountInfo = () => getAccountInfo(ual.activeUser, setAccountInformation);

    const generatePriceString = () => {
        let assetPriceString = STRINGS.loadingEl;

        if (WAX_PAYMENT) {
            let singleAssetWAXPrice = parseFloat(waxPrice);
            let totalWAXPrice = singleAssetWAXPrice * purchaseAmount;

            let singleAssetUSDPrice = parseFloat(usdPrice);
            let totalUSDPrice = singleAssetUSDPrice * purchaseAmount;

            assetPriceString = `${totalWAXPrice.toFixed(2)} ${STRINGS.wax} / $${totalUSDPrice.toFixed(2)}`;
        } else if (FIAT_PAYMENT_ENABLED) {
            let singleAssetUSDPrice = parseFloat(usdPrice);
            let totalUSDPrice = singleAssetUSDPrice * purchaseAmount;

            assetPriceString = ` $${totalUSDPrice.toFixed(2)}`;
        }

        setPrice(assetPriceString);
    };

    const onShowBuyConfirmationModal = () => {
        if (!WAX_SIGN_IN) { return; }
        if (ual.activeUser) {
            setShowBuyConfirmationModal(true);
        } else {
            ual.showModal();
        }
    };

    const onBuy = () => {
        if (!WAX_SIGN_IN) { return; }
        const success = () => {
            setShowBuyConfirmationModal(false);
            setShowSuccessModal(true);
        };
        buyPacks(ual.activeUser, packInfo.get(WAX), purchaseAmount, success, (errorMessage) => setErrorMessage(errorMessage));
        updateResourceInformation();
    };

    const isWaxSoldOut = () => {
        if (!WAX_PAYMENT) {return true;}
        return packInfo.get(WAX) && packInfo.get(WAX).available === 0;
    };

    const isFiatSoldOut = () => {
        if (!FIAT_PAYMENT_ENABLED) {return true;}
        return packInfo.get(FIAT) && packInfo.get(FIAT).available === 0;
    };

    const getBuyLabel = () => {
        let label = STRINGS.comingSoon;
        if (isReleased) {
            label =  purchaseAmount <= 1 ?
                `Buy a ${packImg.name}` :
                `Buy ${purchaseAmount} for ${price}`;

            if (packInfo && isFiatSoldOut() && isWaxSoldOut()) {
                label = STRINGS.soldOut;
            }
        }
        return label;
    };

    const onAmountChange = (newAmount, updateFunction, min, max) => {
        if (newAmount <= min) {
            updateFunction(min);
        } else if (newAmount >= max) {
            updateFunction(max);
        } else {
            updateFunction(newAmount);
        }
    };

    return (
        packImageUrl ?
            <Container
                direction="column"
                isFullWidth={false}
            >
                {
                    packImg.isImg ?
                        <Image alt={packImg.name} src={`${IPFS_ENDPOINT}${packImg.imgHash}`} />
                        :
                        <Video autoPlay
                            playsInline
                            muted
                            loop
                            width="100%"
                            height="100%"
                        >
                            <source
                                src={`${IPFS_ENDPOINT}${packImg.imgHash}`}
                                type="video/mp4"
                            />
                        </Video>
                }
                <Container
                    direction="column"
                    alignItems="center"
                    margin={theme.spacing.s}
                >
                    <Input
                        type="number"
                        max={20}
                        min={1}
                        value={purchaseAmount}
                        onChange={(e) => onAmountChange(parseInt(e.target.value), setPurchaseAmount, 1, 20)}
                        label={STRINGS.packsAmount.replace("{0}", packImg.name)}
                        // infoText={STRINGS.packPurchaseLimit.replace("{0}", packInfo.account_limit).replace("{1}", packInfo.account_limit_cooldown/60)}
                        style={customInputStyle}
                    />
                    <Button disabled={!isReleased || (packInfo && isWaxSoldOut() && isFiatSoldOut()) || purchaseAmount < 1} onClick={onShowBuyConfirmationModal} >
                        { getBuyLabel() }
                    </Button>
                    <Prices>{price}</Prices>
                    {
                        WAX_PAYMENT && packInfo.get(WAX)?
                            <ButtonCaption>
                                {`${packInfo.get(WAX).available} / ${packInfo.get(WAX).max_claimable} ${STRINGS.available} on WAX`}
                            </ButtonCaption>
                            : null
                    }
                    {
                        FIAT_PAYMENT_ENABLED && packInfo.get(FIAT)?
                            <>
                                <ButtonCaption>
                                    {`${packInfo.get(FIAT).available} / ${packInfo.get(FIAT).max_claimable} ${STRINGS.available} on credit card (No Discover Card)`}
                                </ButtonCaption>
                                <ButtonCaption>
                                    {STRINGS.reservedPacks}
                                </ButtonCaption>
                            </>
                            : null
                    }
                </Container>
                {
                    !SINGLE_PROBABILITY ?
                        <Container
                            direction="column"
                            alignItems="center"
                            margin={`0 ${theme.spacing.l}`}
                            padding={theme.spacing.l}
                        >
                            <PackDetailsTitle>{STRINGS.packProbabilities.replace("{0}", packImg.name)}</PackDetailsTitle>
                            <PackDetailsList>
                                {
                                    STRINGS.probabilities.map((pack) => {
                                        let prob = [];
                                        if (pack.template_id === templateId) {
                                            prob = pack.probabilities.map((line, index) => (
                                                <PackDetailItem key={index}>
                                                    <span>
                                                        {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                    </span>
                                                    <span>
                                                        {line.probability}
                                                    </span>
                                                </PackDetailItem>
                                            ));
                                        }
                                        return prob;
                                    })
                                }
                            </PackDetailsList>
                        </Container>
                        : null
                }
                <ConfirmationModal
                    show={showBuyConfirmationModal}
                    onConfirm={onBuy}
                    onClickCustomButton={() => setShowIframeModal(true)}
                    onClose={() => setShowBuyConfirmationModal(false)}
                    confirmationButtonLabel={
                        // purchaseAmount === 1 ?
                        // STRINGS.buyPack.replace('{0}', packInfo.name) :
                        // STRINGS.buyPacks.replace('{0}', purchaseAmount).replace('{1}', price)
                        "Buy with WAX"
                    }
                    customButtonLabel={FIAT_PAYMENT_ENABLED ? "Buy with Credit Card" : undefined}
                    title={
                        purchaseAmount === 1 ?
                            STRINGS.buyPackConfirmation.replace("{0}", packImg.name).replace("{1}", price) :
                            STRINGS.buyPacksConfirmation.replace("{0}", purchaseAmount).replace("{1}", packImg.name).replace("{2}", price)
                    }
                />
                <ErrorModal
                    show={errorMessage !== ""}
                    onClose={() => setErrorMessage("")}
                >
                    {errorMessage}
                    {
                        isCPUErrorMessage(errorMessage) ?
                            <CPUErrorMessage>
                                {STRINGS.cpuErrorMessage}
                            </CPUErrorMessage> :
                            ""
                    }
                    {
                        isNETErrorMessage(errorMessage) ?
                            <CPUErrorMessage>
                                {STRINGS.netErrorMessage}
                            </CPUErrorMessage> :
                            ""
                    }
                </ErrorModal>
                <SuccessModal
                    show={showSuccessModal}
                    onConfirm={() => setShowSuccessModal(false)}
                    onClose={() => setShowSuccessModal(false)}
                    title={STRINGS.successfulBuy}
                >
                    {STRINGS.buySuccessMessage}
                </SuccessModal>
                <FiatIframe
                    show={showIframeModal}
                    onClose={() => setShowIframeModal(false)}
                    amount={purchaseAmount}
                    price={usdPrice}
                    templateId={templateId}
                />
            </Container>
            :
            <Loading />
    );
}

export default WAX_SIGN_IN ? withUAL(Pack) : Pack;
