import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import Footer from '@components/Footer';
import StyledButton from '@components/Button';
import STRINGS from '@utils/strings';
import { SHOW_HEADER } from '@utils/globals';
import ROUTES from '@utils/routes';
import RES from '@utils/resources';

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `linear-gradient(rgba(232, 232, 232, 0.9), rgba(232, 232, 232, 0.9)), url(${RES.images.campaignImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [ theme.mediaQuery.desktopUp ]: {
        backgroundSize: '1200px auto'
    }
}));

const Label = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    margin: `${theme.spacing.xl} 0 ${theme.spacing.m} 0`,
    textAlign: 'center',
    color: theme.colors.tertiaryLight
}));

function NotFound() {
    const history = useHistory();

    return (
        <InfoContainer>
            <Label>{STRINGS.notFound}</Label>
            <StyledButton onClick={() => { history.push(ROUTES.home);}}>
                {STRINGS.goToHome}
            </StyledButton>
        </InfoContainer>
    );
}

export default NotFound;
