import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Modal from '@components/Modal';
import STRINGS from '@utils/strings';
import HubspotForm from 'react-hubspot-form';
import Loading from '@components/Loading';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.tertiary
}));

const Help = styled.div(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.s,
    color: theme.colors.common.grey,
    'a': {
        color: theme.colors.common.grey
    },
    'a:hover': {
        color: theme.colors.primary.dark,
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
}));
function StyledFormModal({ show, onConfirm, onClose, title, style, ...otherProps }) {
    const theme = useTheme();

    const hbspotStyle = {
        position: 'relative',
        maxHeight: '100vh !important',
        overflowY: 'scroll !important',

        '.modalForm': {
            display: 'flex !important',
            flexDirection: 'column !important',
            alignItems: 'center !important',
            marginTop: `24px !important`,
            minHeight: '250px !important'
        },

        '.modalForm p': {
            color: '#1D0B15 !important',
            fontSize: '0.8rem !important',
            fontWeight: '300 !important',
            lineHeight: '150% !important',
            textAlign: 'left'
        },

        '.modalForm label': {
            marginBottom: 0,
            marginTop: '1rem !important',
            width: '100% !important',
            color: '#1D0B15'
        },

        '.modalForm .field': {
            marginBottom: '1rem !important'
        },

        '.modalForm input': {
            border: '1px solid #1D0B15 !important',
            backgroundColor: 'transparent !important',
            borderRadius: '3px !important',
            fontSize: '1rem !important',
            padding: '5px !important',
            color: '#CA1576 !important',
            width: '250px !important',
            outline: 'none !important',
            transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important'
        },

        '.modalForm input::placeholder': {
            color: '#CA1576 !important',
            opacity: '0.5 !important'
        },

        '.modalForm input:active': {
            border: '1px solid #CA1576 !important'
        },
        '.modalForm input:focus': {
            border: '1px solid #CA1576 !important'
        },

        'input.modalButton': {
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) !important',
            transitionDuration: '150ms !important',
            color: '#FFFFFF !important',
            backgroundColor: '#CA1576 !important',
            padding: '4px 24px !important',
            outline: 'none !important',
            fontWeight: '400 !important',
            fontSize: '18px !important',
            lineHeight: '160% !important',
            borderRadius: '0.25rem !important',
            cursor: 'pointer !important',
            marginBottom: '1rem !important',
            border: 'none !important'
        },

        'input.modalButton:hover': {
            backgroundColor: '#A91464 !important',
            color: '#FFFFFF !important'
        },

        'input.modalError': {
            border: '1px solid #CA1515 !important'
        },

        '.modalErrorMsg': {
            fontFamily: '"Roboto", sans-serif !important',
            listStyle: 'none !important',
            color: '#CA1515 !important',
            fontWeight: 500,
            fontSize: '1rem !important',
            marginTop: '0 !important',
            paddingLeft: '0 !important'
        }
    };

    return (<>
        <Modal show={show} onClose={onClose} style={hbspotStyle}>
            <Title>{title}</Title>
            <HubspotForm
                portalId='19612576'
                formId='4c3dd833-4c91-4519-94d1-ddc3e0b3a8ef'
                onSubmit={() => { return (<p>{STRINGS.formSuccessMessage}</p>);}}
                loading={<Loading />}
                cssClass="modalForm"
                errorMessageClass="modalErrorMsg"
                errorClass="modalError"
                submitButtonClass="modalButton"

            />
            <Help>{STRINGS.waxWallet}</Help>
        </Modal>
    </>);
}

export default StyledFormModal;
