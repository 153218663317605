import styled from '@emotion/styled';

const CustomInputLabel = styled.label(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.black,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.s,
    input: {
        maxWidth: '70px'
    }
}));

const CustomInput = styled.input(({ theme }) => ({
    ...theme.typography.pBold,
    color: theme.colors.common.black,
    border: `1px solid ${theme.colors.secondary.dark}`,
    borderRadius: theme.borderRadius.s,
    marginTop: theme.spacing.xxs,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xxs}`,
    outline: 'none',
    backgroundColor: theme.colors.common.white,
    '&:placeholder': {
        color: theme.colors.common.grey,
        opacity: 0.5
    },
    '&:focus, &:active': {
        borderColor: theme.colors.primary.dark
    }
}));

const InfoText = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.secondary.contrastText,
    marginTop: theme.spacing.xxs
}));

function StyledInput({ label, placeholder, type, infoText, style, ...otherProps }) {
    return (
        <div>
            <CustomInputLabel>
                {label}
                <CustomInput type={type} placeholder={placeholder} {...otherProps} />
                {infoText ? <InfoText>{infoText}</InfoText> : ''}
            </CustomInputLabel>
        </div>
    );
}

export default StyledInput;
