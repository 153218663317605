/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Countdown from '@components/Countdown';
import Container from '@components/Container';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import STRINGS from '@utils/strings';
import { isReleased, SHOW_HEADER, IPFS_ENDPOINT } from '@utils/globals';
import RES from '@utils/resources';

const ColInfoContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.s
}));

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(rgba(232, 232, 232, 0.9), rgba(232, 232, 232, 0.9)), url(${RES.images.campaignImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [ theme.mediaQuery.desktopUp ]: {
        backgroundSize: '1200px auto'
    }
}));

const LogoImage = styled.img(({ theme }) => ({
    objectFit: 'contain',
    width: '55vw',
    maxWidth: 350,
    marginTop: theme.spacing.l,
    marginBottom: theme.spacing.s,
    [theme.mediaQuery.tabletLandscapeUp]: {
        width: '35vw'
    }
}));

const Headline = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.colors.tertiary,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    margin: `0 ${theme.spacing.xxs} ${theme.spacing.xs}`,
    [ theme.mediaQuery.desktopUp ]: {
        margin: `0 0 ${theme.spacing.xs}`
    }
}));

const LauchAnnouncement = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginBottom: theme.spacing.xxs
}));

const ReleaseDate = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginTop: theme.spacing.l,
    marginBottom: 0
}));

const ReleaseTime = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch',
    color: theme.colors.tertiary,
    textAlign: 'center',
    marginBottom: 0
}));

const Interview = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    maxWidth: '65ch',
    color: theme.colors.common.black,
    textAlign: 'center',
    marginBottom: theme.spacing.s,
    transition: theme.transition.default,
    'a': {
        color: theme.colors.primary.main
    },
    'a:hover': {
        color: theme.colors.primary.dark
    }
}));

const PackImage = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.primary.dark,
    fontWeight: 'bold'
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.primary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.dark,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        width: 395
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary.main}69`
}));

function Home() {
    const theme = useTheme();

    const [ showFormModal, setShowFormModal ] = useState(false);

    useEffect(() => {
        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);
    }, []);

    const CustomGrid = {
        marginBottom: theme.spacing.m,
        gap: theme.spacing.l,
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            'img': {
                width: 270
            }
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            'img': {
                width: 270
            }
        }
    };

    return (
        <>
            <InfoContainer>
                <ColInfoContainer>
                    <LogoImage alt={STRINGS.campaign} src={RES.images.logo} />
                    <Headline>
                        {STRINGS.homePage.headline}
                    </Headline>
                    <Subtext>
                        {STRINGS.homePage.subtext}
                    </Subtext>
                </ColInfoContainer>
                { isReleased() ? null
                    : <ColInfoContainer>
                        <LauchAnnouncement>
                            {STRINGS.homePage.launchAnnoucement}
                        </LauchAnnouncement>
                        <Countdown />
                        <ReleaseDate>
                            {STRINGS.releaseDate}
                        </ReleaseDate>
                        <ReleaseTime>
                            {STRINGS.releaseTime}
                        </ReleaseTime>
                    </ColInfoContainer>
                }
                <Container direction="column" gap={theme.spacing.l}>
                    <Grid items={RES.samples} style={CustomGrid} />
                    <Container direction="column">
                        <Container  gap={theme.spacing.xxl} style={{ padding: `0 ${theme.spacing.s} ${theme.spacing.xxl} ${theme.spacing.s}` }} wrap="wrap">
                            { RES.packs.map((pack, index) => (
                                <Container key={index} direction="column" gap={theme.spacing.l} style={{ maxWidth: '400px' }}>
                                    <PackImage src={`${IPFS_ENDPOINT}${pack.srcImg}`} alt={pack.caption} />
                                    <Container direction="column">
                                        <PackDescription style={{ fontSize: '22px' }}>{pack.description}</PackDescription>
                                        <PackDescription style={{ textTransform: 'uppercase', color: theme.colors.common.black }}>{pack.quantity}</PackDescription>
                                    </Container>
                                    <Container direction="column" alignItems="center">
                                        <PackDetailsTitle>Pack Probabilities</PackDetailsTitle>
                                        <PackDetailsList>
                                            {pack.probabilities &&
                                                    pack.probabilities.map((line, index) => (
                                                        <PackDetailItem key={index}>
                                                            <span>
                                                                {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                            </span>
                                                            <span>
                                                                {line.probability}
                                                            </span>
                                                        </PackDetailItem>
                                                    ))
                                            }
                                        </PackDetailsList>
                                    </Container>
                                </Container>
                            )) }
                        </Container>
                    </Container>
                </Container>
                <Container>
                    <Interview>
                        {STRINGS.interview}
                    </Interview>
                </Container>
            </InfoContainer>
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
            />
        </>
    );
}

export default Home;
