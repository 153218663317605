import styled from '@emotion/styled';

const StyledButton = styled.button(({ secondary, theme, style }) => ({
    ...theme.typography.p,
    color: secondary ? theme.colors.primary.main : theme.colors.primary.contrastText,
    backgroundColor: secondary ? 'transparent': theme.colors.primary.main,
    padding: `${theme.spacing.xxxs} ${theme.spacing.s}`,
    border: '3px transparent solid',
    borderRadius: theme.borderRadius.s,
    opacity: 1,
    transition: theme.transition.default,
    '&:hover': {
        color: secondary ? theme.colors.primary.dark : theme.colors.primary.contrastText,
        backgroundColor: secondary ? `${theme.colors.primary.light}1F` : theme.colors.primary.dark,
        border: secondary ? `3px ${theme.colors.primary.main} solid` : '3px transparent solid',
        cursor: 'pointer'
    },
    '&:disabled': {
        // color: theme.colors.secondaryDark,
        // backgroundColor: theme.colors.primary.dark,
        cursor: 'not-allowed'
    },
    ...style
}));

function Button({ children, primary, secondary, text, style={}, ...otherProps }) {
    return <StyledButton secondary={secondary} style={style} {...otherProps}>{children}</StyledButton>;
}

export default Button;
