import styled from '@emotion/styled';
import Modal from '@components/Modal';
import StyledButton from '@components/Button';
import STRINGS from '@utils/strings';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 16,
    flexDirection: 'column',
    [theme.mediaQuery.desktopUp]: {
        flexDirection: 'row'
    }
}));

function StyledConfirmationModal({ children, show, customButtonLabel, onClickCustomButton, onConfirm, onClose, title, confirmationButtonLabel=STRINGS.confirm, cancelButtonLabel=STRINGS.cancel, style, ...otherProps }) {
    return (<>
        <Modal show={show} onClose={onClose}>
            <Title>{title}</Title>
            {children}
            <ButtonContainer>
                <StyledButton onClick={onConfirm} >{confirmationButtonLabel}</StyledButton>
                {customButtonLabel ? <StyledButton onClick={onClickCustomButton}>{customButtonLabel}</StyledButton> : null}
                <StyledButton secondary onClick={onClose}>{cancelButtonLabel}</StyledButton>
            </ButtonContainer>
        </Modal>
    </>);
}

export default StyledConfirmationModal;
