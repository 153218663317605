import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Modal from '@components/Modal';

const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    transition: '.1s ease-in-out',
    [theme.mediaQuery.tabletLandscapeUp]: {
        margin: theme.spacing.xxs
    }
}));

const PrincipalImageContainer = styled.div(() => ({
    position: 'relative'
}));

const Badge = styled.span(({ theme }) => ({
    color: theme.colors.primary.contrastText,
    backgroundColor: theme.colors.primary.main,
    borderRadius: theme.borderRadius.l,
    width: theme.size.mintWidth,
    height: theme.size.mintHeight,
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'center',
    lineHeight: theme.size.mintHeight
}));

const ImagesContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    transition: '.1s ease-in-out',
    [theme.mediaQuery.tabletLandscapeUp]: {
        flexDirection: 'row',
        margin: theme.spacing.xxs
    }
}));

const Image = styled.img(({ style }) => ({
    ...style
}));

const Video = styled.video(({ style }) => ({
    ...style
}));

const Caption = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.secondaryDark,
    marginTop: theme.spacing.xxxs,
    marginBottom: theme.spacing.xxxs
}));

function ViewAsset({ asset, onClick }) {
    const theme = useTheme();

    const customModal = {
        width: '90%',
        height: '90%',
        overflowY: 'scroll'
    };

    const mediaStyle = {
        paddingTop: theme.spacing.xxxs,
        objectFit: 'contain',
        maxWidth: '500px',
        width: '85vw',
        maxHeight: '100vh',
        marginBottom: theme.spacing.xs,
        [theme.mediaQuery.tabletLandscapeUp]: {
            width: '40vw',
            maxHeight: '75vh',
            margin: `0 ${theme.spacing.xxs}`
        }
    };

    return (
        <Modal show style={customModal} onClick={onClick}>
            <Container>
                { asset.caption ? <Caption>{asset.caption}</Caption> : null }
                <ImagesContainer>
                    <PrincipalImageContainer>
                        { asset.badge ? <Badge>{asset.badge}</Badge> : null }
                        { asset.type === 'video' ?
                            <Video controls autoPlay loop playsInline style={mediaStyle} width={theme.size.assetWidth}>
                                <source src={asset.srcImg} type="video/mp4" />
                            </Video>
                            : <Image style={mediaStyle} alt={asset.caption} src={asset.srcImg} />
                        }
                    </PrincipalImageContainer>
                    { asset.srcBackImg ? <Image style={mediaStyle} src={asset.srcBackImg} /> : null}
                </ImagesContainer>

            </Container>
        </Modal>
    );
}

export default ViewAsset;
