const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

if (IS_PRODUCTION) {
    setInfo(208770, {
        imgHash: 'QmfDKFhPgrNFkySJqUdFw93JCm97f8oUjRK2eLi2A2HAzS',
        name: 'Single Pass Pack',
        isImg: true,
        variant: ''
    });
    setInfo(208771, {
        imgHash: 'QmTFkaPMmrz4i7t2XDtBDt1QWfPQN6dfWMrPSz79xNtGgg',
        name: 'VIP Pass Pack',
        isImg: true,
        variant: ''
    });
} else {
    setInfo(134594, {
        imgHash: 'QmfDKFhPgrNFkySJqUdFw93JCm97f8oUjRK2eLi2A2HAzS',
        name: 'Single Pass Pack',
        isImg: true,
        variant: ''
    });
    setInfo(134595, {
        imgHash: 'QmTFkaPMmrz4i7t2XDtBDt1QWfPQN6dfWMrPSz79xNtGgg',
        name: 'VIP Pass Pack',
        isImg: true,
        variant: ''
    });
}
